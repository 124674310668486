import { Component, OnInit } from '@angular/core';
import { CookieServiceService } from 'src/app/services/cookie-service.service';
@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss']
})
export class CookieComponent implements OnInit {

  constructor(
    private cookiesService : CookieServiceService

  ) { }

  ngOnInit(): void {
  }

  decline(){
    this.cookiesService.declineCookie()
  }
  accept() {

    this.cookiesService.acceptCookie()
    console.log("aaa")
}


}
