<div class="best-logistic-area ptb-100 main-bg">
    <div class="container">
        <form [formGroup]="reserve">
            <stepper #stepper (counterChange)="onStepChange($event)">
                <step [title]="'จอง'" [iconTemplate]="icon_firststep" [iconTemplateColor]="iconColor_firststep">
                    <ng-template #icon_firststep>
                        <img src="../../../../assets/img/move/progress1.png" style="width: 76px; height: 84px"
                            alt="progress1">
                    </ng-template>
                    <ng-template #iconColor_firststep>
                        <img src="../../../../assets/img/move/progressColor1.png" style="width: 76px; height: 84px"
                            alt="progress1">
                    </ng-template>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 bg-move d-flex align-items-center">
                            <div class="logistic-text flex-column ">
                                <h2><span>Simple Moving</span></h2>
                                <h2 style="font-size: 28px;">กรุณาเลือกเวลาขนย้าย</h2>
                                <p style="color: #5799d0; font-size: 18px;">วันที่</p>
                                <div class="input-group mb-3 input-group-sm">
                                    <input style="border-radius: 15px; border: 2px solid #5799d0; width:500px"
                                        (click)="d.toggle()" class="form-control " placeholder="เลือกวันที่" name="dp"
                                        ngbDatepicker #d="ngbDatepicker" [minDate]="model"
                                        (dateSelect)="onDateSelect($event)">
                                </div>
                                <p style="color: #5799d0; font-size: 18px;">เวลา</p>
                                <div class="input-group mb-3 input-group-sm ">
                                    <select class="form-select form-select-lg mb-3" formControlName="time" id="cars"
                                        style="border-radius: 15px; border: 2px solid #5799d0;" placeholder="">
                                        <option value="" disabled selected>เลือกเวลา</option>
                                        <option *ngFor="let time of timeOptions" [value]="time.key">{{time.key}}</option>
                                    </select>
                                </div>
                                <div class="d-flex justify-content-end pt-4">
                                    <button *ngIf="buttonNextStep1" style="border-radius: 35px; " (click)="stepper.nextStep()"
                                        class="default-btn-one mr-3 custom-next-button">ต่อไป</button>
                                    <button *ngIf="!buttonNextStep1" style="border-radius: 35px;"
                                        class="default-btn-one mr-3 custom-next-button">ต่อไป</button>
                                    </div>

                            </div>
                            <!-- <a routerLink="/about" class="default-btn-one">Read More</a> -->
                        </div>

                    </div>
                </step>
                <step [title]="'การจัดส่งของ'" [iconTemplate]="icon_secondstep" [iconTemplateColor]="iconColor_secondstep">
                    <ng-template #icon_secondstep>
                        <img src="../../../../assets/img/move/progress2.png" style="width: 76px; height: 84px" alt="">
                    </ng-template>
                    <ng-template #iconColor_secondstep>
                        <img src="../../../../assets/img/move/progressColor2.png" style="width: 76px; height: 84px" alt="">
                    </ng-template>
                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="container" style="height: 700px; overflow-y: scroll">
                                <p style="color: #5799d0;; font-size: 18px; font-weight: bold;"><span>Step 1</span></p>
                                <h2 style="font-size: 28px; padding-bottom: 10px;">ข้อมูลสถานที่รับของ</h2>
                                <div class="service-card shadow" style="border-radius: 35px;">
                                    <input id="originSearch" type="text" class="form-control border-color "
                                        formControlName="address_sender" placeholder="ระบุที่อยู่สําหรับรับของ"
                                        aria-label="ระบุที่อยู่รับของ" aria-describedby="basic-addon2">
                                    <!-- <i class='bx bxs-ship'></i> -->
                                    <div class="pt-4">
                                        <h3 class="pt-60 d-flex justify-start"
                                            style="font-size: 18px; margin-bottom: 25px;">ประเภทอาคาร
                                        </h3>
                                        <select class="form-select" formControlName="building_sender" id="cars"
                                            style="border-radius: 15px; border: 2px solid #5799d0;">
                                            <option *ngFor="let building of buildingOptions" [value]="building.value">
                                                {{building.key}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="pt-4">
                                        <h3 class="pt-60 d-flex justify-start" style="font-size: 18px;">
                                            ข้อมูลที่อยู่</h3>
                                        <input type="text" class="form-control border-color"
                                            placeholder="เลขที่, ถนน, ซอย, แขวง/ตําบล, เขต/อําเภอ, จังหวัด, รหัสไปรษณีย์" formControlName="moreaddress_sender"
                                            aria-label="ระบุที่อยู่รับของ" aria-describedby="basic-addon2">
                                    </div>
                                    <div class="pt-4">
                                        <h3 class="pt-60 d-flex justify-start" style="font-size: 18px;">
                                            ข้อมูลการติดต่อ</h3>
                                        <div class="row">
                                            <div class="col-lg-6 col-sm-6">
                                                <input type="text" class="form-control border-color"
                                                    formControlName="name_sender" placeholder="ชื่อ - นามสกุล"
                                                    aria-label="ระบุที่อยู่รับของ" aria-describedby="basic-addon2">

                                            </div>
                                            <div class="col-lg-6 col-sm-6">
                                                <input type="tel" class="form-control border-color"
                                                    formControlName="tel_sender" placeholder="เบอร์โทรติดต่อ"
                                                    aria-label="ระบุที่อยู่รับของ" aria-describedby="basic-addon2">

                                            </div>
                                            <div class="col-lg-6 col-sm-6" style="padding-top: 2%">
                                                <input type="email" class="form-control border-color"
                                                    formControlName="email_sender" placeholder="อีเมล"
                                                    aria-label="ระบุที่อยู่อีเมล" aria-describedby="basic-addon2">

                                            </div>


                                        </div>
                                    </div>

                                </div>
                                <p style="color: #5799d0; font-size: 18px; font-weight: bold;"><span>Step 2</span></p>
                                <h2 style="font-size: 28px; padding-bottom: 10px;">ข้อมูลสถานที่จัดส่งของ</h2>

                                <div class="service-card shadow " style="border-radius: 35px;">
                                    <input id="finishSearch" type="text" class="form-control border-color"
                                        formControlName="address_reciever" placeholder="ระบุที่อยู่สําหรับรับของ"
                                        aria-label="ระบุที่อยู่รับของ" aria-describedby="basic-addon2">

                                    <div class="pt-4">
                                        <h3 class="pt-60 d-flex justify-start"
                                            style="font-size: 18px; margin-bottom: 25px; ">ประเภทอาคาร
                                        </h3>
                                        <select class="form-select mb-3" formControlName="building_reciever" id="cars"
                                            style="border-radius: 15px; border: 2px solid #5799d0;">
                                            <option *ngFor="let building of buildingOptions" [value]="building.value">
                                                {{building.key}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="pt-4">
                                        <h3 class="pt-60 d-flex justify-start" style="font-size: 18px;">
                                            ข้อมูลที่อยู่</h3>
                                        <input type="text" formControlName="moreaddress_reciever"
                                            class="form-control border-color" placeholder=" เลขที่, ถนน, ซอย, แขวง/ตําบล, เขต/อําเภอ, จังหวัด, รหัสไปรษณีย์  "
                                            aria-label="ระบุที่อยู่รับของ" aria-describedby="basic-addon2">
                                    </div>
                                    <div class="pt-4">
                                        <h3 class="pt-60 d-flex justify-start" style="font-size: 18px;">
                                            ข้อมูลการติดต่อ</h3>
                                        <div class="row">
                                            <div class="col-lg-6 col-sm-6">
                                                <input type="text" formControlName="name_reciever"
                                                    class="form-control border-color" placeholder="ชื่อ - นามสกุล"
                                                    aria-label="ระบุที่อยู่รับของ" aria-describedby="basic-addon2">

                                            </div>
                                            <div class="col-lg-6 col-sm-6">
                                                <input type="tel" class="form-control border-color"
                                                    formControlName="tel_reciever" placeholder="เบอร์โทรติดต่อ"
                                                    aria-label="ระบุที่อยู่รับของ" aria-describedby="basic-addon2">

                                            </div>
                                            <div class="col-lg-6 col-sm-6" style="padding-top: 2%">
                                                <input type="email" class="form-control border-color"
                                                    formControlName="email_reciever" placeholder="อีเมล"
                                                    aria-label="ระบุที่อยู่อีเมล" aria-describedby="basic-addon2">

                                            </div>


                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <p style="color: #5799d0; font-size: 20px;" class="d-flex justify-content-center"><span>ระยะทาง</span> <span>&nbsp;&nbsp;{{distance}}&nbsp;&nbsp;</span>  ก.ม.</p>
                            <div id="map" style="width: 100% ; height: 100%"></div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between pt-4" style="margin-top: 3%">
                        <button style="border-radius: 35px;" (click)="stepper.prevStep() ;calcRoute();"
                            class="default-btn-one mr-3 custom-back-button">กลับ</button>


                        <button *ngIf="buttonNextStep2" style="border-radius: 35px;" (click)="stepper.nextStep()"
                            class="default-btn-one mr-3 custom-next-button">ต่อไป</button>

                            <!-- <button  style="border-radius: 35px;" (click)="calcRoute()"
                            class="default-btn-one mr-3 custom-next-button">cal</button> -->

                        <button *ngIf="!buttonNextStep2" style="border-radius: 35px;"
                            class="default-btn-one mr-3 custom-next-button">ต่อไป</button>

                    </div>




                </step>
                <step [title]="'เลือกยานพาหนะ'" [iconTemplate]="icon_thirdstep" [iconTemplateColor]="iconColor_thirdstep">
                    <ng-template #icon_thirdstep>

                        <img src="../../../../assets/img/move/progress3.png" alt="progress2"
                            style="width: 76px; height: 84px">
                    </ng-template>
                    <ng-template #iconColor_thirdstep>

                        <img src="../../../../assets/img/move/progressColor3.png" alt="progress2"
                            style="width: 76px; height: 84px">
                    </ng-template>
                    <div class="best-logistic-area ptb-100">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-lg-6 col-md-12">
                                    <div class="logistic-text">
                                        <h2 style="font-size: 28px;">เลือกยานพาหนะ</h2>
                                        <p style="font-size: 18px; color:black">Lorem ipsum dolor sit amet, consectetur
                                            adipiscing elit. Etiam nec ex at ligula rhoncus convallis ac ac urna. In hac
                                            habitasse platea dictumst.</p>
                                    </div>
                                    <div id="radios">
                                        <label for="car" class="material-icons">
                                            <input type="radio" id="car" name="car_type" value="รถ 4 ล้อ"
                                                formControlName="car_type" (click)="onSelectedCar()" />
                                            <span>รถ 4 ล้อ</span>
                                        </label>
                                        <label for="pickup" class="material-icons mx-4">
                                            <input type="radio" name="car_type" id="pickup" value="รถกระบะตู้ทึบ"
                                                formControlName="car_type" (click)="onSelectedPickup()" />
                                            <span>รถกระบะตู้ทึบ</span>
                                        </label>
                                        <label for="truck" class="material-icons">
                                            <input type="radio" name="car_type" id="truck" value="รถ 6 ล้อ"
                                                formControlName="car_type" (click)="onSelectedTruck()" />
                                            <span>รถ 6 ล้อ</span>
                                        </label>
                                    </div>
                                    <!-- <a routerLink="/about" class="default-btn-one">Read More</a> -->
                                </div>

                                <div *ngIf="car" class="col-lg-6 col-md-12 d-flex align-items-center">
                                    <div class="logistic-image d-flex flex-column align-items-center text-center">
                                        <img src="../../../../assets/img/move/car1.png" alt="image" class="w-50 mb-4">
                                        <h2 style="font-size: 18px;">รถ 4 ล้อ</h2>
                                        <p style="margin-bottom: none;">รับนํ้าหนักได้สูงสุด 500 กิโลกรัม</p>
                                        <p>Max Cargo size (cm) : 170 x 150 x 210</p>
                                        <p>เหมาะสําหรับขนย้ายเฟอร์นิเจอร์ ขนาดไม่ใหญ่มาก </p>
                                        <p>หรือ กล่องใส่ของ</p>
                                    </div>
                                </div>
                                <div *ngIf="pickup" class="col-lg-6 col-md-12">
                                    <div class="logistic-image">
                                        <img src="../../../../assets/img/move/car3.png" alt="image">
                                        <h2 style="font-size: 18px;">รถกระบะตู้ทึบ</h2>
                                        <p style="margin-bottom: none;">รับนํ้าหนักได้สูงสุด 1,000 กิโลกรัม</p>
                                        <p>Max Cargo size (cm) : 300 x 170 x 210</p>
                                        <p>เหมาะสําหรับการขนย้ายที่พัก</p>
                                        <p>เช่น การย้ายคอนโด หรือ อพาร์ทเม้น</p>
                                    </div>
                                </div>
                                <div *ngIf="truck" class="col-lg-6 col-md-12">
                                    <div class="logistic-image">
                                        <img style="width: 250px; height: 213px;"
                                            src="../../../../assets/img/move/car2.png" alt="image">
                                        <h2 style="font-size: 18px;">รถ 6 ล้อ</h2>
                                        <p style="margin-bottom: none;">รับนํ้าหนักได้สูงสุด 1,000 กิโลกรัม</p>
                                        <p>Max Cargo size (cm) : 300 x 170 x 210</p>
                                        <p>เหมาะสําหรับการขนย้ายที่พัก</p>
                                        <p>เช่น การย้ายคอนโด หรือ อพาร์ทเม้น</p>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between pt-4">
                        <button style="border-radius: 35px;" (click)="stepper.prevStep()"
                            class="default-btn-one mr-3 custom-back-button">กลับ</button>

                        <button *ngIf="buttonNextStep3" style="border-radius: 35px;" (click)="stepper.nextStep()"
                            class="default-btn-one mr-3 custom-next-button">ต่อไป</button>

                        <button *ngIf="!buttonNextStep3" style="border-radius: 35px;"
                            class="default-btn-one mr-3 custom-next-button">ต่อไป</button>

                    </div>
                </step>
                <step [title]="'บริการพิเศษ'" [iconTemplate]="icon_fourthstep" [iconTemplateColor]="iconColor_fourthstep">
                    <ng-template #icon_fourthstep>
                        <img src="../../../../assets/img/move/progress4.png" alt="progress2"
                            style="width: 76px; height: 84px">

                    </ng-template>
                    <ng-template #iconColor_fourthstep>
                        <img src="../../../../assets/img/move/progressColor4.png" alt="progress2"
                            style="width: 76px; height: 84px">

                    </ng-template>

                    <div class="best-logistic-area ptb-50">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-lg-12 col-md-12">
                                    <div class="logistic-text">
                                        <h2 style="font-size: 18px; margin-bottom: 3%;">
                                            คุณต้องการเพิ่มบริการเสริมสําหรับการจองของคุณ หรือไม่</h2>
                                        <div id="radios" style="margin-bottom: 3%;">
                                            <label for="service_staff" class="material-icons">
                                                <input type="radio" name="service_type" id="service_staff"
                                                    formControlName="service_type" value="ผู้ช่วยยกสิ่งของ" />
                                                <span>ผู้ช่วยยกสิ่งของ</span>
                                            </label>
                                            <label for="service_fix" class="material-icons mx-4">
                                                <input type="radio" name="service_type" id="service_fix"
                                                    formControlName="service_type"
                                                    value="การประกอบแยกชิ้นส่วน / การถอดแยกชิ้นส่วน" />
                                                <span>การประกอบแยกชิ้นส่วน / การถอดแยกชิ้นส่วน</span>
                                            </label>
                                            <label for="service_pack" class="material-icons">
                                                <input type="radio" name="service_type" id="service_pack"
                                                    formControlName="service_type" value="การจัดห่อ" />
                                                <span>การจัดห่อ</span>
                                            </label>
                                            <label for="service_stairs" class="material-icons mx-4">
                                                <input type="radio" name="service_type" id="service_stairs"
                                                    formControlName="service_type" value="การขนย้ายลงบันได" />
                                                <span>การขนย้ายลงบันได</span>
                                            </label>
                                        </div>
                                    </div>
                                    <h2 style="font-size: 18px; margin-bottom: 2%;">
                                        รายละเอียดเพิ่มเติมเกี่ยวกับการขนย้ายของคุณ</h2>
                                    <div class="mb-3">
                                        <textarea class="form-control" id="exampleFormControlTextarea1"
                                           formControlName="more_information" rows="3"></textarea>
                                    </div>
                                    <h2 style="font-size: 18px; margin-bottom: 2%;">อัพโหลดภาพรายการของคุณ</h2>
                                    <div class="mb-3" style="width: 50%;">
                                        <input class="form-control" type="file" id="formFile">
                                    </div>

                                    <h2 style="font-size: 18px; margin-bottom: 2%;">
                                        อัพโหลดไฟล์เพิ่มเติมเกี่ยวกับการขนย้าย</h2>
                                    <div class="mb-3" style="width: 50%;">
                                        <input class="form-control" type="file" id="formFile">
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between pt-4">
                                <button style="border-radius: 35px;" (click)="stepper.prevStep()"
                                    class="default-btn-one mr-3 custom-back-button">กลับ</button>

                                <button style="border-radius: 35px;" (click)="stepper.nextStep()"
                                    class="default-btn-one mr-3 custom-next-button">ต่อไป</button>

                            </div>
                        </div>
                    </div>

                </step>
                <step [title]="'สรุปการจอง'" [iconTemplate]="icon_fifthstep" [iconTemplateColor]="iconColor_fifthstep">
                    <ng-template #icon_fifthstep>
                        <img src="../../../../assets/img/move/progress5.png" alt="progress2"
                            style="width: 76px; height: 84px">
                    </ng-template>
                    <ng-template #iconColor_fifthstep>
                        <img src="../../../../assets/img/move/progressColor5.png" alt="progress2"
                            style="width: 76px; height: 84px">
                    </ng-template>
                    <div class="best-logistic-area ptb-50">
                        <div class="container" style="padding: 15px;">
                            <div class="row align-items-center ">
                                <div class="col-lg-12 col-md-12 gap-3 " style="padding: none;">
                                    <div class="logistic-text d-flex justify-content-between align-items-center"
                                        style="background-color: #5799d0; padding: 20px;  border-radius: 15px;">
                                        <h2 style="font-size: 18px; color: #fff;">
                                            สรุปการจอง Move</h2>
                                        <!-- <h2 style="font-size: 18px; color: #fff;">
                                            วัน/เดือน/ปี: {{selectedDate}} </h2> -->

                                        <h2 style="font-size: 18px; color: #fff;">
                                            เวลานัดหมาย: {{selectedDate}} ({{this.reserve.get('time').value}}) </h2>

                                    </div>

                                    <div class="d-flex justify-content-between  gap-2" >
                                        <div class="custom-outline-dotted d-flex" style="width: 100%">
                                            <div class="logistic-text">

                                                <h2 style="font-size: 20px; color: #5799d0;">รายละเอียดสถานที่ส่งของ</h2>
                                                <p>สถานที่: {{this.reserve.get('name_reciever').value}} </p>
                                                <p>ประเภทอาคาร: {{this.reserve.get('building_reciever').value}} </p>
                                                <p>ชื่อ: {{this.reserve.get('name_sender').value}} </p>
                                                <p>โทร: {{this.reserve.get('tel_sender').value}} </p>
                                                <p>อีเมลล์: {{this.reserve.get('email_sender').value}} </p>

                                            </div>
                                            <div class="logistic-text" style="margin-left: 10%;">
                                                <h2 style="font-size: 20px; color: #5799d0;">ข้อมูลที่อยู่เพิ่มเติม</h2>
                                                <p>{{this.reserve.get('moreaddress_sender').value}}</p>
                                            </div>
                                        </div>
                                        <div style="width: 100%; " >
                                            <div class="logistic-text">
                                                <h2 style="font-size: 20px; color: #5799d0;">ข้อมูลการจอง</h2>
                                                <!-- <p>{{this.reserve.get('moreaddress_sender').value}}</p> -->
                                                <p>ประเภทรถ: {{this.reserve.get('car_type').value}} </p>
                                                <p>ระยะทาง {{distance}} กม.</p>
                                                <p>บริการเพิ่มเติม: {{this.reserve.get('service_type').value}} </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between gap-2" style="margin-top: 2%;">
                                        <div class="custom-outline-dotted d-flex" style="width: 100%">
                                            <div class="logistic-text">

                                                <h2 style="font-size: 20px; color: #5799d0;">รายละเอียดสถานที่ส่งของ</h2>
                                                <p>สถานที่: {{this.reserve.get('name_reciever').value}} </p>
                                                <p>ประเภทอาคาร: {{this.reserve.get('building_reciever').value}} </p>
                                                <p>ชื่อ: {{this.reserve.get('name_sender').value}} </p>
                                                <p>โทร: {{this.reserve.get('tel_sender').value}} </p>
                                                <p>อีเมลล์: {{this.reserve.get('email_sender').value}} </p>

                                            </div>
                                            <div class="logistic-text" style="margin-left: 10%;">

                                                <h2 style="font-size: 20px; color: #5799d0;">ข้อมูลที่อยู่เพิ่มเติม</h2>

                                            </div>
                                        </div>
                                        <div style="width: 100%; height: max-content;" >
                                            <div class="logistic-text">
                                                <h2 style="font-size: 20px; color: #5799d0;">รวมเป็นยอดเงิน <span>XXXXX</span>  บาท</h2>

                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="container custom-result-text">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-12 ">
                                                <div class="row custom-outline-dotted">
                                                    <div class="col-lg-6">
                                                        <div class="logistic-text">

                                                            <h2>รายละเอียดสถานที่ส่งของ</h2>
                                                            <p>สถานที่: {{this.reserve.get('name_sender').value}} </p>
                                                            <p>ประเภทอาคาร: {{this.reserve.get('building_sender').value}} </p>
                                                            <p>ชื่อ: {{this.reserve.get('name_sender').value}} </p>
                                                            <p>โทร: {{this.reserve.get('tel_sender').value}} </p>
                                                            <p>อีเมลล์: {{this.reserve.get('email_sender').value}} </p>

                                                        </div>
                                                    </div>
                                                    <div class="col-lg-5 custom-outline-dotted">
                                                        <div class="logistic-text">

                                                            <h2>ข้อมูลที่อยู่เพิ่มเติม</h2>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-lg-6 col-md-12 d-flex custom-outline-dotted">
                                                <div class="logistic-text">
                                                    <h2>ข้อมูลการจอง</h2>
                                                    <p>{{this.reserve.get('moreaddress_sender').value}}</p>
                                                </div>
                                            </div>


                                        </div>
                                    </div> -->

                                    <!-- <div class="container custom-result-text">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-12 d-flex">
                                                <div class="logistic-text">

                                                    <h2>รายละเอียดสถานที่รับของ</h2>
                                                    <p>สถานที่: {{this.reserve.get('name_reciever').value}} </p>
                                                    <p>ประเภทอาคาร: {{this.reserve.get('building_reciever').value}} </p>
                                                    <p>ชื่อ: {{this.reserve.get('name_reciever').value}} </p>
                                                    <p>โทร: {{this.reserve.get('tel_reciever').value}} </p>
                                                    <p>อีเมลล์: {{this.reserve.get('email_reciever').value}} </p>


                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-12 d-flex">
                                                <div class="logistic-text">
                                                    <h2>ยอดรวม</h2>
                                                </div>
                                            </div>


                                        </div>
                                    </div> -->

                                    <!-- <div class="container custom-result-text">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12">
                                                <div class="logistic-text">
                                                    <h2>สรุปค่าใช้จ่าย</h2>
                                                    <p>ค่าใช้จ่ายรถ:       บาท</p>
                                                    <p>ค่าใช้บริการเสริม {{this.reserve.get('service_type').value}}:       บาท</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="container custom-result-text">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12">
                                                <div class="logistic-text">
                                                    <h2>สรุปค่าใช้จ่าย</h2>
                                                    <p>ค่าใช้จ่ายรถ:       บาท</p>
                                                    <p>ค่าใช้บริการเสริม {{this.reserve.get('service_type').value}}:       บาท</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div> -->


                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between pb-4 ml-3" style="padding-left: 15px;">
                        <button style="border-radius: 35px;" (click)="stepper.prevStep()"
                            class="default-btn-one mr-3 custom-back-button">กลับ</button>

                        <button style="border-radius: 35px;" (click)="stepper.nextStep()"
                            class="default-btn-one mr-3 custom-next-button">ต่อไป</button>

                    </div>
                </step>
                <step [title]="'ชําระเงิน'" [iconTemplate]="icon_sixthstep" [iconTemplateColor]="iconColor_sixthstep">
                    <ng-template #icon_sixthstep>
                        <img src="../../../../assets/img/move/progress6.png" alt="progress2"
                            style="width: 76px; height: 84px">
                    </ng-template>
                    <ng-template #iconColor_sixthstep>
                        <img src="../../../../assets/img/move/progressColor6.png" alt="progress2"
                            style="width: 76px; height: 84px">
                    </ng-template>
                    <div class="best-logistic-area ptb-50">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-lg-12 col-md-12">
                                    <div class="logistic-text">
                                        <h2 style="font-size: 22px; margin-bottom: 3%;">
                                            การชําระเงิน</h2>
                                        <div class="col-lg-6 col-md-6">
                                            <p style="font-size: 18px;">ยอดรวม บาท</p>
                                        </div>

                                        <h2 style="font-size: 22px; margin: 3% 0;">
                                            โปรดเลือกวิธีการชําระเงินที่คุณต้องการ</h2>
                                        <div id="radios" style="margin-bottom: 3%;">
                                            <label for="service_staff" class="material-icons">
                                                <input type="radio" name="services" id="service_staff"
                                                    value="service_staff" />
                                                <span>ชําระด้วยบัตรเครดิต</span>
                                            </label>
                                            <label for="service_fix" class="material-icons mx-4">
                                                <input type="radio" name="services" id="service_fix"
                                                    value="service_fix" />
                                                <span>เก็บเงินปลาทาง</span>
                                            </label>
                                        </div>
                                        <h2 style="font-size: 22px; margin-bottom: 3%;">
                                            อัพโหลดหลักฐานการชําระเงิน</h2>
                                        <div class="mb-3" style="width: 50%;">
                                                <input class="form-control" type="file" id="formFile">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between" style="margin-top: 3%;">
                                <button style="border-radius: 35px;" (click)="stepper.prevStep()"
                                    class="default-btn-one mr-3 custom-back-button">กลับ</button>

                                <button style="border-radius: 35px;"
                                    class="default-btn-one mr-3 custom-next-button">ยืนยันการจอง</button>

                            </div>
                        </div>
                    </div>
                </step>
                <step [title]="'เสร็จสิ้น'" [iconTemplate]="icon_finish" [iconTemplateColor]="iconColr_finish">
                    <ng-template #icon_finish>
                        <img src="../../../../assets/img/move/progress7.png" alt="progress2"
                            style="width: 76px; height: 84px">

                    </ng-template>
                    <ng-template #iconColr_finish>
                        <img src="../../../../assets/img/move/progressColor7.png" alt="progress2"
                            style="width: 76px; height: 84px">

                    </ng-template>
                </step>
            </stepper>

        </form>
    </div>
    <!-- <pre>{{ reserve}}</pre> -->
</div>
<!-- <pre>{{reserve.value| json}}</pre> -->

<!-- <div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Modal title</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Modal body text goes here.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary">Save changes</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div> -->
