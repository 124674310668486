<!-- Footer Area-->
<footer class="footer-area pt-100 pb-70">
    <div class="container ">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget">
                    <div class="logo" style="padding-top: 50px;">
                        <img src="../../../../assets/img/move/logo_no_bg.png" alt="logo">
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-80">
                    <!-- <h3>Company</h3> -->

                    <ul class="footer-text ">
                        <li><a routerLink="/about">ทฤษฎี</a></li>
                        <li><a routerLink="/services">เกี่ยวกับเรา</a></li>
                        <li><a routerLink="/">ห้องข่าว</a></li>
                        <li><a routerLink="/">ดาวน์โหลด</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-50">
                    <!-- <h3>Support</h3> -->

                    <ul class="footer-text">
                        <li><a routerLink="/faq">การสนับสนุน</a></li>
                        <li><a routerLink="/privacy-policy">ศูนย์บริการความช่วยเหลือ</a></li>
                        <li><a routerLink="/terms-conditions">ข้อตกลงและเงื่อนไข</a></li>
                        <!-- <li><a routerLink="/">Community</a></li>
                        <li><a routerLink="/contact">Contact Us</a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget">
                    <!-- <h3>Contact Info</h3> -->

                    <ul class="info-list">
                        <li><a routerLink="/privacy-policy">นโยบายคุ้มครองข้อมูลส่วนบุคคล</a></li>
                        <li><a routerLink="/terms-conditions">ข้อตกลงในการใช้งาน</a></li>
                        <!-- <li style="color: #fff;"><i class='bx bxs-location-plus'></i> 6th Floor, Barbosa, Sidney</li>
                        <li><i class='bx bxs-envelope'></i> <a href="mailto:hello@floza.com">hello@floza.com</a></li>
                        <li><i class='bx bxs-envelope'></i> <a href="mailto:contact@floza.com">contact@floza.com</a></li>
                        <li><i class='bx bxs-phone'></i> <a href="tel:+0123456789">+0123 456 789</a></li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <ul class="footer-socials d-flex justify-content-center">
        <li><a href="#" target="_blank"><i style="color: #fff; width: 20px; height: 20px;"  class="fab fa-facebook-f"></i></a></li>
        <!-- <li><a href="#" target="_blank"><i class='bx bxl-twitter' ></i></a></li>
        <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> -->
    </ul>
</footer>
<!-- End Footer Area -->

<!-- Footer bottom Area -->
<!-- <div class="footer-bottom">
    <div class="container">
        <p>© Floza is Proudly Owned by <a href="https://hibootstrap.com/" target="_blank">HiBootstrap</a></p>
    </div>
</div> -->
<!-- End Footer bottom Area -->

<!-- Go Top -->
<div class="go-top">
    <i style="background: #5799d0;" class='bx bx-chevrons-up'></i>
</div>
<!-- End Go Top -->
