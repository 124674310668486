<nav aria-label="Progress">
    <ol role="list" class="d-flex w-100 justify-content-between align-items-center" style="list-style: none;">
        <li class="position-relative" *ngFor="let step of internalSteps">

            <!-- Arrow separator for lg screens and up -->
            <!-- <div *ngIf="!step.isLast" class="separator" aria-hidden="true">
                <div class=" separator-icon"></div>
                <svg class="separator-icon" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                    <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentcolor"
                        stroke-linejoin="round" />
                </svg>
            </div> -->
            <!-- Completed Step -->

            <a (click)="setStep(step)" class="">

                <!-- <ng-container *ngIf="(currentStep <= step.stepNumber) ||
        (currentStep > step.stepNumber && step.isLast) ; then stepOngoing; else stepDone">
        </ng-container> -->
                <ng-container *ngIf="currentStep > step.stepNumber">

                    <div class="d-flex flex-column align-items-center">
                        <ng-template-outlet [ngTemplateOutlet]="step.iconTemplateColor"></ng-template-outlet>
                        <span class="">{{step.title}}</span>
                    </div>
                </ng-container>


                <ng-container *ngIf="currentStep == step.stepNumber">
                    <div class="d-flex flex-column align-items-center">
                        <ng-template-outlet [ngTemplateOutlet]="step.iconTemplateColor"></ng-template-outlet>
                        <span class="">{{step.title}}</span>
                    </div>
                </ng-container>

                <ng-container *ngIf="currentStep < step.stepNumber">

                    <div class="d-flex flex-column align-items-center step">
                        <ng-template-outlet [ngTemplateOutlet]="step.iconTemplate"></ng-template-outlet>
                        <span class="">{{step.title}}</span>
                    </div>

                </ng-container>

            </a>


        </li>
    </ol>
</nav>


<div class="p-2">
    <ng-content></ng-content>
</div>
