import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList } from '@angular/core';
import { StepComponent } from './step/step.component';

interface Step {
  title?: string;
  active: boolean;
  stepNumber: number;
}

@Component({
  selector: 'stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],

})
export class StepperComponent {

  currentStep: number = 1;
  internalSteps!: QueryList<StepComponent>;

  @Input() counter = 1;
  @Output() counterChange = new EventEmitter<number>();

  ngOnChanges() {

    this.currentStep = this.counter;
    if (this.internalSteps) {
      this.setChildSteps();
    }
  }

  @ContentChildren(StepComponent)
  set stepsContent(steps: QueryList<StepComponent>) {
    if (steps) {
      this.internalSteps = steps;
      this.internalSteps.last.isLast = true;
      this.internalSteps.first.isFirst = true;
      this.registerSteps();
    }
  }

  nextStep() {
    this.currentStep++;
    this.internalSteps.toArray().forEach((step: StepComponent, idx: number) => {
      step.currentStep = this.currentStep;
    });
    this.counterChange.emit(this.currentStep);
  }

  prevStep() {
    this.currentStep--;
    this.internalSteps.toArray().forEach((step: StepComponent, idx: number) => {
      step.currentStep = this.currentStep;
    });
    this.counterChange.emit(this.currentStep);
  }

  setStep(step: Step) {

    this.currentStep = step.stepNumber;
    this.internalSteps.toArray().forEach((step: StepComponent, idx: number) => {
      step.currentStep = this.currentStep;
    });
    this.counterChange.emit(this.currentStep);
  }

  private registerSteps(): void {
    this.internalSteps.toArray().forEach((step: StepComponent, idx: number) => {
      step.currentStep = this.currentStep;
      step.stepNumber = idx + 1;
    });
  }

  private setChildSteps(): void {
    this.internalSteps.toArray().forEach((step: StepComponent, idx: number) => {
      step.currentStep = this.currentStep;
    });
  }
}
