import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss']
})
export class StepComponent implements OnInit {


  @Input('title') title?: string;
  @Input() active = false;
  @Input() iconTemplate: TemplateRef<any>
  @Input() iconTemplateColor: TemplateRef<any>


  stepNumber = 0;
  isLast = false;
  isFirst = false;
  currentStep = 1;

  constructor() {
  }

  ngOnInit() {
  }

  ngDoCheck() {
    //
  }
}
