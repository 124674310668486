<div class="custom-banner">
    <div class="container">
        <div class="custom-content">
            <!-- <span>Since 1992</span> -->
            <h1 >ขนย้ายเร็วถึงไว</h1>
            <h1 >เลือกใช้ <span style="color: #5799d0;">Move</span></h1>

        </div>
    </div>
</div>
<div class="best-logistic-area ptb-100">
    <div class="container">
        <div class="row align-items-center">

            <div class="col-lg-6 col-md-12">
                <div class="logistic-image">
                    <img src="../../../../assets/img/move/service_pic1.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="logistic-text">
                    <h2><span style="color: #5799d0;">Move</span> ให้บริการแบบ Door to Door</h2>
                    <h2 style="font-size: 28px;">คุณจึงมั่นใจได้ว่าสินค้าจะถูฏจัดส่งอย่างรวดเร็ว</h2>
                    <ul>
                        <li>
                            <p style="font-size: 18px; color:black">มีรถ และ บริการเสิรมให้เลือกใช้ตามขนาดการจัดส่งที่ต้องการ</p>
                        </li>
                        <li>
                            <p style="font-size: 18px; color:black">บริการจัดสั่ง 24 ชั่วโมง ทั่วประเทศ</p>
                        </li>
                        <li>
                            <p style="font-size: 18px; color:black">รถทุกคันมี GPS เพื่อให้คุณสามารถติดตามการจัดสั่งได้ Real Time</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="best-logistic-area ptb-50">
    <div class="container">
        <div class="row align-items-center">

            <div class="col-lg-6 col-md-12">
                <div class="logistic-image">
                    <img src="../../../../assets/img/move/service_pic1.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="logistic-text">
                    <h2 style="font-size: 22px;">ตัวช่วยเรื่องการส่งของ</h2>
                    <h2 style="font-size: 28px;">ขนส่ง...ขนย้ายทุกอย่างที่คุณต้องการ</h2>
                            <p style="font-size: 18px; color:black">บริการทุกวัน 24 ชม.</p>
                            <p style="font-size: 18px; color:black">ราคาถูก</p>
                            <p style="font-size: 18px; color:black">ขนส่งที่ไว้ใจได้</p>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="services-area ptb-100 pb-70">
    <div  class="container">
        <h2 class="d-flex justify-content-center" style="color:black">บริการเพื่อให้ตรงกับการขนย้ายของคุณ</h2>
        <p class="d-flex justify-content-center" style="font-size: 20px; color: black;">ตั้งแต่การขนส่งขนาดเล็กไปจนถึงรถ 6 ล้อ</p>
        <div class="section-title">

        </div>
    </div>
</div>

<div class="best-logistic-area" style="padding: 15px 0;">
    <div class="container">
        <div class="row align-items-center">

            <div class="col-lg-6 col-md-6">
                    <!-- <i class='bx bxs-truck'></i> -->
                    <div class="d-flex justify-content-center text-center">
                        <div>

                            <img src="../../../../assets/img/move/car3.png" style="width: 243px; height: 201px" alt="">
                            <div class="pt-4">
                                <h3  style="color: black; font-size: 20px;">รถบรรทุกขนาดใหญ่</h3>
                                <p class="pt-2" style="font-size: 14px; color: black;" >นํ้าหนักสูงสุด 2 ตัน (2,000 กิโลกรัม)</p>
                                <p style="font-size: 14px; color: black;">ขนาดสินค้าสูงสุด (ชม.): 170 x 150 x 210</p>

                            </div>
                        </div>

                    </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="service-card shadow " style="border-radius: 35px;">
                    <div>
                        <h3 class="pt-60" style="color: #5799d0;">อัตราค่าบริการ</h3>
                        <p style="color:black;font-size: 18px">ราคาพื้นฐาน (Base Fare) : 300 บาท</p>
                        <p style="color:black;font-size: 18px">(รวมบริการยกสินค้าขึ้นและลง</p>
                        <p style="color:black;font-size: 18px">โดยคนขับรถที่ จุดจอดรถรับ / ส่งสินค้าเท่านั้น)</p>
                        <p style="color:black;font-size: 18px">ค่าบริการต่อระยะทาง 11 บาท / กม.</p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="best-logistic-area" style="padding: 50px ;">
    <div class="container">
        <div class="row align-items-center">

            <div class="col-lg-6 col-md-12 d-flex justify-content-center text-center">
                <div class="logistic-image ">
                    <img style="height: 213px; width: 250px" src="../../../../assets/img/move/car2.png" alt="image">
                    <h3  style="color: black; font-size: 20px;">รถบรรทุกขนาดใหญ่</h3>
                    <p class="pt-2" style="font-size: 14px; color: black;" >นํ้าหนักสูงสุด 2 ตัน (2,000 กิโลกรัม)</p>
                                <p style="font-size: 14px; color: black;">ขนาดสินค้าสูงสุด (ชม.): 170 x 150 x 210</p>

                </div>
            </div>
            <div class="col-lg-6 col-md-12" styl>
                <div class="service-card shadow " style="border-radius: 35px;">
                    <div>
                        <h3 class="pt-60" style="color: #5799d0;">อัตราค่าบริการ</h3>
                        <p style="color:black;font-size: 18px">ราคาพื้นฐาน (Base Fare) : 700 บาท</p>
                        <p style="color:black;font-size: 18px">(รวมบริการยกสินค้าขึ้นและลง</p>
                        <p style="color:black;font-size: 18px">โดยคนขับรถที่ จุดจอดรถรับ / ส่งสินค้าเท่านั้น)</p>
                        <p style="color:black;font-size: 18px">ค่าบริการต่อระยะทาง 15 บาท / กม.</p>

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<div style="padding-top: 35px;" class="d-flex justify-content-center">
    <button style="border-radius: 35px; text-decoration: none; justify-content: center;" routerLink="/reserve" class="default-btn-one mr-3 custom-button">จองตอนนี้</button>

</div>
<div class="best-logistic-area ptb-100">
    <div class="container">
        <div class="row align-items-center">

            <div class="col-lg-6 col-md-12">
                <div class="logistic-image">
                    <img src="../../../../assets/img/move/service_pic1.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="logistic-text">
                    <ol>
                        <li>
                            <p style="font-size: 18px; color:black">ระบุจุดรับส่งสินค้่า</p>
                        </li>
                        <li>
                            <p style="font-size: 18px; color:black">เลือกประเภทรถที่ต้องการขนส่ง</p>
                        </li>
                        <li>
                            <p style="font-size: 18px; color:black">สะดวก รู้ราคาค่าขนส่งก่อนกาเรียกงาน</p>
                        </li>
                        <li>
                            <p style="font-size: 18px; color:black">ระบุรายละเอียดสินค้าเพิ่มเติม ให้คนขับได้สะดวกผ่านหน้าแอป</p>
                        </li>
                        <li>
                            <p style="font-size: 18px; color:black">ติดตามสถาณะขนส่งได้เรียลไทม์ พร้อมพูดคุยกับคนขับได้ตลอดเวลา ผ่านแชทบนหน้าแอปพลิเคชั่น</p>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="services-area pb-70" style="padding-top: 50px;">
    <div  class="container">
        <div class="section-title">
            <!-- <span>Our Services</span> -->
            <h2 style="color:black">ดาวน์โหลดแอปพลิเคชั่นของเรา</h2>
            <h2 style="color:black">เพื่อประสบการณ์จองรถที่ยิ่งดีขึ้น</h2>
        </div>

        <div class="d-flex justify-content-center">
            <img src="../../../../assets/img/move/about_avaliable.png" style="width: 180px; height:fit-content; padding-top: 35px; margin: 0 25px;" alt="" class="">
            <img src="../../../../assets/img/move/about_qr.png" alt="">
        </div>



        <div class="row">

            <div class="d-flex justify-content-center" >
                <img style="width: 450px; height: 450px;" src="../../../../assets/img/move/about_phone.png" alt="">

            </div>

            <div class="d-flex justify-content-center" style="padding-top: 15px;">
                <a style="border-radius: 35px; width: 150px; font-size: 20px; background-color: #122434; " routerLink="/contact" class="default-btn-one mr-3">โหลดเลย! <!DOCTYPE html>
                <html lang="en">
                <head>
                    <meta charset="UTF-8">
                    <meta http-equiv="X-UA-Compatible" content="IE=edge">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <title>Document</title>
                </head>
                <body>

                </body>
                </html></a>

            </div>
        </div>

    </div>
</div>
