<!-- <div class="flex">
  <div class="bg-teal-100 w-32 h-16 p-4 flex items-center">
    <ng-container *ngIf="(currentStep <= stepNumber) ||
             (currentStep > stepNumber && isLast) ; then stepOngoing; else stepDone">
    </ng-container>
    <ng-template #stepOngoing>
      <span class="inline-flex items-center justify-center p-2 rounded-full bg-white  w-10 h-10">{{stepNumber}}</span>
    </ng-template>
    <ng-template #stepDone>
      <div class="check"></div>
    </ng-template>
    <div class="ml-2 font-medium">
      <ng-content select="[step-title]"></ng-content>
    </div>
  </div>


</div>
<ng-container *ngIf="
            (currentStep === stepNumber) ||
            (currentStep > stepNumber && isLast) ||
            (currentStep < stepNumber && isFirst)">
  <div class="divider">
    <div class="line" *ngIf="!isLast"></div>
  </div>
  <div class="content">
    <ng-content select="[step-content]"></ng-content>
    <ng-content select="[step-action]"></ng-content>
  </div>
</ng-container> -->
<div *ngIf="(currentStep === stepNumber) ||
(currentStep > stepNumber && isLast) ||
(currentStep < stepNumber && isFirst)">

  <ng-content></ng-content>
</div>
