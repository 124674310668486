<!-- Page banner Area -->
<div class="page-banner bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Our Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Our Services Area -->
<!-- <div class="our-services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Our Services</span>
            <h2>Safe, Faster and Relaible Logistics Services</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/services/services1.jpg" alt="image">
                    <div class="service-caption">
                        <h3>Road Freight</h3>
                        <p>But I must explain to you how all this mistaken idea of denouncing  I will give you a complete account of the system.</p>
                        <a routerLink="/services-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/services/services2.jpg" alt="image">
                    <div class="service-caption">
                        <h3>Ocean Freight</h3>
                        <p>But I must explain to you how all this mistaken idea of denouncing  I will give you a complete account of the system.</p>
                        <a routerLink="/services-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/services/services3.jpg" alt="image">
                    <div class="service-caption">
                        <h3>Air Freight</h3>
                        <p>But I must explain to you how all this mistaken idea of denouncing  I will give you a complete account of the system.</p>
                        <a routerLink="/services-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/services/services4.jpg" alt="image">
                    <div class="service-caption">
                        <h3>B2B Exchange</h3>
                        <p>But I must explain to you how all this mistaken idea of denouncing  I will give you a complete account of the system.</p>
                        <a routerLink="/services-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/services/services5.jpg" alt="image">
                    <div class="service-caption">
                        <h3>Home Shipping</h3>
                        <p>But I must explain to you how all this mistaken idea of denouncing  I will give you a complete account of the system.</p>
                        <a routerLink="/services-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/services/services6.jpg" alt="image">
                    <div class="service-caption">
                        <h3>Office Moving</h3>
                        <p>But I must explain to you how all this mistaken idea of denouncing  I will give you a complete account of the system.</p>
                        <a routerLink="/services-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <ul class="pagination">
                    <li class="page-item"><a class="page-link" routerLink="/services"><span aria-hidden="true">&laquo;</span></a></li>
                    <li class="page-item active"><a class="page-link" routerLink="/services">1</a></li>
                    <li class="page-item"><a class="page-link" routerLink="/services">2</a></li>
                    <li class="page-item"><a class="page-link" routerLink="/services">3</a></li>
                    <li class="page-item"><a class="page-link" routerLink="/services"><span aria-hidden="true">&raquo;</span></a></li>
                </ul>
            </div>
        </div>
    </div>
</div> -->
<!-- Our End Services Area -->

<!-- Best Logistic Area -->
<div class="best-logistic-area ptb-100">
    <div class="container">
        <div class="row align-items-center">

            <div class="col-lg-6 col-md-12">
                <div class="logistic-image">
                    <img src="../../../../assets/img/move/service_pic1.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="logistic-text">
                    <h2><span>ขนย้ายไปสถานที่ใหม่ของคุณ</span></h2>
                    <h2 style="font-size: 28px;">ด้วยขั้นตอนไม่กี่ขั้นตอน</h2>
                    <p style="font-size: 18px; color:black">move ทําให้การย้ายบ้านเป็นเรื่องง่ายแพลทฟอร์มของเราสร้างขึ้นเพื่อให้คุณจองการขนย้ายได้อย่างง่ายดาย และสะดวกภายในเวลาไม่กี่นาที และไม่กี่ขั้นตอนง่ายๆเมื่อจองแล้วผู้ขนย้ายมืออาชีพของเราจะดูแลการขนย้ายของคุณ ให้คุณได้เพลิดเพลินไปกับสถาณที่ใหม่ไรกังวลกับอัตราค่าบริการที่คงที่และไม่มีค่าใช้จ่ายแฝง</p>
                </div>
                <!-- <a routerLink="/about" class="default-btn-one">Read More</a> -->
            </div>
        </div>
    </div>
</div>
<div class="services-area pt-4 pb-70">
    <div  class="container">
        <h2 class="d-flex justify-start" style="color:black">บริการเพื่อให้ตรงกับการขนย้ายของคุณ</h2>
        <p class="d-flex justify-start" style="font-size: 20px; color: black;">บริการเพิ่มเติมจะทําให้คุณขนย้ายได้ปลอดภัย ง่ายดาย และราบรื่น</p>
        <div class="section-title">
            <!-- <span>Our Services</span> -->

        </div>

        <div class="row">

            <div class="col-lg-4 col-sm-6">
                <div class="service-card ">
                    <!-- <i class='bx bxs-truck'></i> -->
                    <img style="width: 243px; height: 201px" src="../../../../assets/img/move/service_pic2.png" alt="">
                    <div class="pt-4">
                        <h3 style="color: black;">กําลังคน</h3>
                        <p style="font-size: 14px; color: black; ">คนขับหรือผู้ช่วยจะช่วยขนย้าย</p>
                        <p style="font-size: 14px; color: black;">สิ่งของหรือเฟอร์นิเจอร์ไปยังรถขนส่ง</p>

                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card">
                    <!-- <i class='bx bx-transfer'></i> -->
                    <img src="../../../../assets/img/move/service_pic3.png" alt="" style="width: 243px; height: 201px">
                    <div  class="pt-4">
                        <h3 style="color: black;">การขนของขึ้นลงบันได</h3>
                        <p style="font-size: 14px; color: black;">แพ๊คสิ่งของที่แตกง่ายในกล่อง</p>
                        <p style="font-size: 14px; color: black;">เพื่อการขนย้ายที่ราบรื่น</p>
                        <p style="font-size: 14px; color: black;">และป้องกันความเสียหายขณะ</p>
                        <p style="font-size: 14px; color: black;">ขนของขึ้นลงบันได</p>


                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="service-card">
                    <!-- <i class='bx bxs-truck'></i> -->
                    <img src="../../../../assets/img/move/service_pic4.png" style="width: 243px; height: 201px" alt="">
                    <div class="pt-4">
                        <h3>การห่อหุ้มป้องกัน</h3>
                        <p style="font-size: 14px; color: black;">ห่อหุ้มสิ่งของหรือเฟอร์นิเจอร์ของคุณ</p>
                        <p style="font-size: 14px; color: black;">ด้วยพลาสติดใสหรือพลาสติกกะนกระแทก</p>
                        <p style="font-size: 14px; color: black;">และลดความเสียหายหรือการขีดข่วน</p>
                        <p style="font-size: 14px; color: black;">ระหว่างการขนส่ง</p>
                    </div>
                </div>
            </div>

        </div>
        <!-- <a style="border-radius: 35px; width: 120px; justify-content: center;" routerLink="/contact" class="default-btn-one mr-3 d-flex justify-content-center">ดูทั้งหมด</a> -->

    </div>
</div>
<div class="best-logistic-area" style="padding: 15px 0;">
    <div class="container">
        <div class="row align-items-center">

            <div class="col-lg-6 col-md-12">
                <div class="logistic-image">
                    <img src="../../../../assets/img/move/car2.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="logistic-text">
                    <h4><span>รถ 6 ล้อ</span></h4>
                    <p style="color:black;font-size: 18px">รับนํ้าหนักสูงสุดได้ 3,000 กิโลกรัม</p>
                    <p style="color:black;font-size: 18px">Max cargo size (cm) : 500 x 220 x 230</p>
                    <p style="color:black;font-size: 18px">เหมาะสําหรับการขนย้ายสิ่งของปริมาณมาก เช่น การขนย้ายบ้าน หรือ ย้ายออฟฟิศ</p>
                </div>
                <!-- <a routerLink="/about" class="default-btn-one">Read More</a> -->
            </div>
        </div>
    </div>
</div>
<div class="best-logistic-area" style="padding: 15px 0;">
    <div class="container">
        <div class="row align-items-center">

            <div class="col-lg-6 col-md-12">
                <div class="logistic-image">
                    <img src="../../../../assets/img/move/car1.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="logistic-text">
                    <h4><span>รถกระบะตู้ทึบ</span></h4>
                    <p style="color:black;font-size: 18px">รับนํ้าหนักสูงสุดได้ 500 กิโลกรัม</p>
                    <p style="color:black;font-size: 18px">Max cargo size (cm) : 500 x 220 x 230</p>
                    <p style="color:black;font-size: 18px">เหมาะสําหรับขนย้ายเฟอร์นิเจอร์ขนาดไม่ใหญ่มาก หรือกล่องใส่ของ (มีหลังคาคลุมสิ่งของ)</p>

                </div>
                <!-- <a routerLink="/about" class="default-btn-one">Read More</a> -->
            </div>
        </div>
    </div>
</div>
<div class="best-logistic-area" style="padding-top: 15px; padding-bottom: 5%;">
    <div class="container">
        <div class="row align-items-center">

            <div class="col-lg-6 col-md-12">
                <div class="logistic-image">
                    <img src="../../../../assets/img/move/car3.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="logistic-text">
                    <h4><span>รถ 4 ล้อ</span></h4>
                    <p style="color:black;font-size: 18px;">รับนํ้าหนักสูงสุดได้ 3,000 กิโลกรัม</p>
                    <p style="color:black;font-size: 18px">Max cargo size (cm) : 500 x 220 x 230</p>
                    <p style="color:black;font-size: 18px">เหมาะสําหรับการขนย้ายสิ่งของปริมาณมาก เช่น การขนย้ายบ้าน หรือ ย้ายออฟฟิศ</p>
                </div>
                <!-- <a routerLink="/about" class="default-btn-one">Read More</a> -->
            </div>
        </div>
    </div>
</div>
<!-- End Best Logistic Area -->

<!-- Newsletter Area -->
<!-- <div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required="" autocomplete="off">
                        <button type="submit" class="btn btn-primary">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Newsletter Area -->
