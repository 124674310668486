<div class="cookie-banner">
    <div class="container">
        <p style="color: #fff">
            Move ใช้คุกกี้เพื่อเพิ่มประสิทธิภาพในการให้บริการ
            และส่งมอบประสบการณ์ที่ดีในการใช้งานเว็บไซต์ ตรวจสอบและทําความเข้าใจ
            <a routerLink="cookie">นโยบายคุกกี้ </a> และ
            <a routerLink="policy">นโยบายความเป็นส่วนตัว</a> หากคุณไม่ปฎิเสธ ดําเนินต่อในการคลิกปุ่ม
        </p>
        <button (click)="decline()" class="btn btn-decline">ปฏิเสธ</button>
        <button  (click)="accept()" class="btn btn-primary btn-blue" type="submit">ยอมรับ</button>
    </div>
</div>
