<div style="margin-top: 10%;">
    <div class="container mt-4">
        <div class="digital-content">
            <h3 style="color: #5799d0;"> <span>นโยบายคุกกี้</span></h3>
            <div class="bar"></div>
            <p>เมื่อท่านได้เข้าสู่เว็บไซต์ www.seed-thailand.com ข้อมูลที่เกี่ยวข้องกับการเข้าสู่เว็บไซต์ของท่านจะถูกเก็บเอาไว้ในรูปแบบของคุกกี้ โดยนโยบายคุกกี้นี้จะอธิบายถึงความหมาย การทำงาน วัตถุประสงค์ รวมถึงการลบและการปฏิเสธการเก็บ

                คุกกี้เพื่อความเป็นส่วนตัวของท่าน โดยการเข้าสู่เว็บไซต์นี้ถือว่าท่านได้อนุญาตให้เราใช้คุกกี้ตามนโยบายคุกกี้ที่มีรายละเอียดดังต่อไปนี้
        </div>
        <div class="mt-4">
            <h4 style="color: #5799d0;">คุกกี้คืออะไร</h4>
            <p>คุกกี้ คือ ข้อมูล text files บนคอมพิวเตอร์ที่เก็บรวบรวม standard internet log information และข้อมูลพฤติกรรมการเยี่ยมชมเว็บไซต์ของท่าน

                คุกกี้ เป็น ไฟล์ขนาดเล็กที่เว็บไซต์หรือผู้ให้บริการมีการส่งไปจัดเก็บในคอมพิวเตอร์ของท่านผ่านทางเว็บบราวเซอร์ (ถ้าท่านอนุญาต) ซึ่งช่วยให้ระบบของเว็บไซต์หรือผู้ให้บริการรู้จักบราวเซอร์ของท่าน และสามารถจับและจดจำ

                ข้อมูลบางอย่าง รวมถึงข้อมูลพฤติกรรมการเยี่ยมชมเว็บไซต์ของท่าน อย่างไรก็ตามรหัสในไฟล์คุกกี้จะทำให้เว็บไซต์รู้เพียงว่าว่าท่านเป็นผู้ใช้โดยเฉพาะ แต่จะไม่สามารถระบุชื่อหรือที่อยู่หรือข้อมูลอื่นของท่านได้ เว้นแต่ท่านจะได้ให้

                ข้อมูลดังกล่าวแก่เว็บไซต์หรือตั้งค่ากำหนดในบราวเซอร์โดยอัตโนมัติ ซึ่งทำให้เมื่อท่านเยี่ยมชมเว็บไซต์ของบริษัทฯ บริษัทฯจะเก็บข้อมูลของท่านผ่านทางคุกกี้หรือเทคโนโลยีอื่นที่คล้ายกันโดยอัตโนมัติ</p>
        </div>
        <div class="mt-4">
            <h4 style="color: #5799d0;">เราใช้คุกกี้เพื่ออะไรบ้าง</h4>
            <p>
                มีคุกกี้อยู่หลายประเภท อย่างไรก็ตาม เว็บไซต์ของบริษัทฯ ใช้คุกกี้เพื่อวัตถุประสงค์ดังต่อไปนี้
            </p>
            <ul>
                <li>
                    <p>ตามวัตถุประสงค์ – บริษัทฯ ใช้คุกกี้เพื่อจะได้จดจำท่านบนหน้าเว็บไซด์ของบริษัทฯ และจดจำสิ่งที่ท่านตั้งค่าไว้ก่อนหน้า รวมถึงภาษาที่ท่านใช้และพื้นที่ที่ท่านอยู่ และมีการใช้คุกกี้ของบุคคลที่หนึ่งและบุคคลที่สามร่วมกัน</p>
                </li>
                <li>
                    <p>เพื่อการโฆษณา – บริษัทฯ ใช้คุกกี้เพื่อเก็บข้อมูลเกี่ยวกับหน้าเว็บไซต์ของเราที่ท่านเยี่ยมชม เนื้อหาที่ท่านดู ลิงค์ที่กด และข้อมูลบราวเซอร์ อุปกรณ์ และ IP (Internet Protocol) Address ของท่าน บางครั้งบริษัทฯ ก็ แบ่งปันข้อมูลแก่บุคคลที่สามเพื่อวัตถุประสงค์ทางการโฆษณา บริษัทฯอาจจะแบ่งปันข้อมูลออนไลน์ที่รวบรวมผ่านคุกกี้กับพันธมิตรด้านการโฆษณาของบริษัทฯ ซึ่งหมายความว่าเมื่อท่านเยี่ยมชมหน้าเว็บไซต์อื่น ท่านอาจเห็นโฆษณาตามรูปแบบการเรียกดูของท่านบนหน้าเว็บไซด์ของบริษัทฯ ทั้งนี้บริษัทฯ จะเปิดเผยตามสิทธิในสัญญาหรือตามกฎหมายเท่านั้น</p>
                </li>
                <li>
                    <p>ช่วยให้บริษัทฯ จดจำความต้องการของท่านตามกิจกรรมในเว็บไซต์ก่อนหน้าหรือปัจจุบันซึ่งช่วยให้บริษัทฯ สามารถจัดหาบริการที่ดีขึ้นและเหมาะสำหรับการเข้าชมของท่านในอนาคต</p>
                </li>
                <li>
                    <p>ช่วยให้บริษัทฯ สามารถวิเคราะห์ประสิทธิภาพของโฆษณา และวิเคราะห์การใช้งานเว็บไซต์ได้</p>
                </li>
                <li>
                    <p>รวบรวมข้อมูลโดยรวมเกี่ยวกับการเข้าชมเว็บไซต์และการโต้ตอบในเว็บไซต์เพื่อมอบประสบการณ์และเครื่องมือในเว็บไซต์ที่ดีขึ้นในอนาคต</p>
                </li>
                <li>
                    <p>เพื่อความปลอดภัยและป้องกันการเข้าใช้บัญชีของท่านจากผู้ไม่ได้รับอนุญาต
                    </p>
                </li>
            </ul>
        </div>
        <div class="mt-4">
            <h4 style="color: #5799d0;">การจัดการคุกกี้</h4>
            <p>ท่านสามารถตั้งค่าบราวเซอร์ของท่านไม่ให้ยอมรับการใช้คุกกี้ได้ ดูรายละเอียดเพิ่มเติมได้ที่ www.aboutcookies.org นอกจากนี้เว็บไซต์ที่ระบุไปในข้างต้นยังบอกถึงวิธีนำคุกกี้ออกจากบราวเซอร์ของท่าน อย่างไรก็ตาม ในบางกรณี การตั้งค่าดังกล่าวอาจทำให้ไม่สามารถใช้บางฟีเจอร์บนหน้าเว็บไซต์ของบริษัทฯได้</p>
            <p>หากท่านเลือกปฏิเสธที่จะใช้คุกกี้ที่บริษัทฯ ใช้สำหรับวัตถุประสงค์เพื่อการโฆษณา เราจะหยุดการใช้คุกกี้นั้น แต่ทั้งนี้ไม่ได้หมายความว่าท่านจะไม่เห็นโฆษณาเมื่อท่านเยี่ยมชมเว็บไซต์ของบริษัทฯ แต่หมายความเพียงว่าโฆษณาที่ท่านเห็นอาจจะไม่ตรงกับความสนใจของท่าน ท่านสามารถลบและปฏิเสธการเก็บคุกกี้ได้โดยศึกษาตามวิธีการที่ระบุในแต่ละเว็บเบราว์เซอร์ที่ท่านใช้งานอยู่ตามลิงก์ดังนี้ (ข้อมูล ณ วันที่ เปลี่ยนตามวันที่ขึ้น)</p>
            <ul>
                <li>Chrome</li>
                <li>Chrome for android</li>
                <li>Chrome for ios</li>
                <li>Firefox</li>
                <li>Internet Explorer</li>
                <li>Microsoft Edge</li>
                <li>Safari</li>
                <li>Safari for ios</li>
            </ul>
        </div>
        <div class="mt-4 mb-4">
            <h4 style="color: #5799d0;">การเปลี่ยนแปลงนโยบายคุกกี้</h4>
            <p>นโยบายคุกกี้นี้อาจมีการปรับปรุงแก้ไขตามโอกาสเพื่อให้เป็นไปตามกฎระเบียบ ดังนั้นเราขอแนะนำให้ท่านตรวจสอบให้แน่ใจว่าท่านได้เข้าใจการเปลี่ยนแปลงตามข้อกำหนดดังกล่าว</p>
        </div>
</div>

