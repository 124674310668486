import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder } from '@angular/forms';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';




let directionsService = new google.maps.DirectionsService();
let directionsRenderer = new google.maps.DirectionsRenderer({ suppressMarkers: true });


@Component({
    selector: 'app-reserve',
    templateUrl: './reserve.component.html',
    styleUrls: ['./reserve.component.scss']
})
export class ReserveComponent implements OnInit {

    current = new Date()
    car: any = true;
    pickup = false;
    truck = false;
    map: google.maps.Map;
    originLocation = { lat: null, lng: null };
    finishLocation = { lat: null, lng: null };
    distance:any = 0;
    buttonNextStep1 = false
    buttonNextStep2 = false
    buttonNextStep3 = false
    buttonNextStep4 = false
    buttonNextStep5 = false
    originMarker:any
    finishMarker:any
    private _success = new Subject<string>();


    staticAlertClosed = false;
    successMessage = '';

    @ViewChild('staticAlert', {static: false}) staticAlert: NgbAlert;
    @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert: NgbAlert;




    reserve = this.formBuilder.group(({
        date: '',
        time: '',
        address_sender: '',
        building_sender: '',
        moreaddress_sender: '',
        name_sender: '',
        tel_sender: '',
        email_sender: '',
        address_reciever: '',
        building_reciever: '',
        moreaddress_reciever: '',
        name_reciever: '',
        tel_reciever: '',
        email_reciever: '',
        car_type: '',
        service_type: '',
        more_information: '',





    }))

    selectedDate = '';
    model: NgbDateStruct = {
        day: new Date().getDate() + 1,
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear()
    };

    timeOptions = [
        { key: '8.00 AM', value: 8.00 },
        { key: '8.30 AM', value: 8.5 },
        { key: '9.00 AM', value: 9 },
        { key: '9.30 AM', value: 9.5 },
        { key: '10.00 AM', value: 10 },
        { key: '10.30 AM', value: 10.5 },
        { key: '11.00 AM', value: 11 },
        { key: '11.30 AM', value: 11.5 },
        { key: '12.00 PM', value: 12 },
        { key: '12.30 PM', value: 12.5 },
        { key: '1.00 PM', value: 13 },
        { key: '1.30 PM', value: 13.5 },
        { key: '2.00 PM', value: 14 },
        { key: '2.30 PM', value: 14.5 },
        { key: '3.00 PM', value: 15 },
        { key: '3.30 PM', value: 15.5 },
        { key: '4.00 PM', value: 16 },
        { key: '4.30 PM', value: 16.5 },
        { key: '5.00 PM', value: 17 },
        { key: '5.30 PM', value: 17.5 },
        { key: '6.00 PM', value: 18 }


    ]

    buildingOptions = [
        { key: 'บ้าน', value: 'บ้าน' },
        { key: 'อาคารสูง', value: 'อาคารสูง' },
        { key: 'โรงเรียน', value: 'โรงเรียน' },
        { key: 'ออฟฟิศ / สํานักงาน', value: 'ออฟฟิศ' },

    ]


    constructor(
        private formBuilder: FormBuilder,
        public activeModal: NgbActiveModal,
        ) { }

    ngOnInit(): void {
        // this.initMap()

        setTimeout(() => this.staticAlert.close(), 20000);

        this._success.subscribe(message => this.successMessage = message);
        this._success.pipe(debounceTime(5000)).subscribe(() => {
          if (this.selfClosingAlert) {
            this.selfClosingAlert.close();
          }
        });

        this.onChangeStep1()
        this.onChangeStep2()
        this.onChangeStep3()

    }


    //   google map


    initMap(): void {

        this.map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
            center: { lat: 13.8208012, lng: 100.5593237 },
            zoom: 16,
        });

        const inputOrigin = document.getElementById("originSearch") as HTMLInputElement;
        const inputFinish = document.getElementById("finishSearch") as HTMLInputElement;

        const options = {
            componentRestrictions: { country: "th" },
            fields: ["address_components", "geometry", "icon", "name"],
            strictBounds: false,
            types: [],
        };

        directionsRenderer.setMap(this.map);

        const searchBox = new google.maps.places.Autocomplete(inputOrigin, options);

        searchBox.addListener("place_changed", () => {
            const originPlace = searchBox.getPlace();
            this.originLocation.lat = originPlace.geometry.location.lat();
            this.originLocation.lng = originPlace.geometry.location.lng();
            // var originMarker = new google.maps.Marker({
            //     position: this.originLocation,
            //     title: "Hello World!"
            // });

            // originMarker.setMap(this.map);
            if (this.finishLocation.lat != null) {
                this.calcRoute()
            }
            console.log(originPlace.geometry.location.lat()
            )
        })

        const searchBoxFinish = new google.maps.places.Autocomplete(inputFinish, options);


        searchBoxFinish.addListener("place_changed", () => {
            const finishPlace = searchBoxFinish.getPlace();
            this.finishLocation.lat = finishPlace.geometry.location.lat();
            this.finishLocation.lng = finishPlace.geometry.location.lng();
            // var finishMarker = new google.maps.Marker({
            //     position: this.finishLocation,
            //     icon: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
            //     title: "Hello World!"
            // });

            // finishMarker.setMap(this.map);

            if (this.originLocation.lat != null) {
                this.calcRoute()
            }
            console.log(finishPlace.geometry.location.lat()
            )
        })


        this.map.addListener("bounds_changed", () => {
            searchBox.setBounds(this.map.getBounds() as google.maps.LatLngBounds);
        });

        this.map.addListener("bounds_changed", () => {
            searchBoxFinish.setBounds(this.map.getBounds() as google.maps.LatLngBounds);
        });

    }



    onCalculate() {
        this.reserve.valueChanges.subscribe(value => {
            console.log('value: ', value);
            if (value.address_reciever != '' && value.address_sender != '') {
                setTimeout(() => {
                    this.calcRoute()

                }, 100)
            }
        })
    }

    calcRoute() {
        console.log("cals")
        var request: google.maps.DirectionsRequest = {
            origin: new google.maps.LatLng(this.originLocation.lat, this.originLocation.lng),
            destination: new google.maps.LatLng(this.finishLocation.lat, this.finishLocation.lng),
            travelMode: google.maps.TravelMode.DRIVING
        };
        directionsService.route(request).then((result) => {
            if (result['status'] == "OK") {
                console.log(result)
                directionsRenderer.setDirections(result);

                this.originMarker = new google.maps.Marker({
                    position: result.routes[0].legs[0].start_location,
                    icon: '../../../../assets/img/move/marker-icon-origin.png',
                    title: "Hello World!"
                });


                this.finishMarker = new google.maps.Marker({
                    position: result.routes[0].legs[0].end_location,
                    icon: '../../../../assets/img/move/marker-icon.png',
                    title: "Hello World!"
                });

                this.originMarker.setMap(this.map);
                this.finishMarker.setMap(this.map);

                var calDistance = result.routes[0].legs[0].distance.value

                if (calDistance) {
                    this.distance = (calDistance / 1000).toFixed(2)
                    console.log(this.distance)
                }


            }
        })
    }


    onStepChange(evt: any) {
        // console.log(evt)
        if (evt == 2) {
            setTimeout(() => {
                this.initMap()
                // this.onCalculate()
            }, 150)
        }
    }





    // end of google map







    // Choose The car

    onSelectedCar() {
        this.car = true
        this.pickup = false
        this.truck = false

    }

    onSelectedPickup() {
        this.car = false
        this.pickup = true
        this.truck = false
    }


    onSelectedTruck() {
        this.car = false
        this.pickup = false
        this.truck = true
    }

    onDateSelect(evt: any) {
        var day = evt.day.toString();
        var month = evt.month.toString();
        var year = evt.year.toString();

        var formatString = day + '/' + month + '/' + year
        console.log('formatString: ', formatString);
        this.selectedDate = formatString;
        this.reserve.patchValue({
            date: year + '-' + month + '-' + day
        })




    }
    // button next step
    onChangeStep1() {
        this.reserve.valueChanges.subscribe(value=>{
            if (value.date && value.time) {
                this.buttonNextStep1 = true;
            }
            else{
                this.buttonNextStep1 = false;
            }
        })
    }

    onChangeStep2() {
        this.reserve.valueChanges.subscribe(value=>{
            if (value.address_sender &&
                value.building_sender &&
                value.moreaddress_sender &&
                value.name_sender &&
                value.tel_sender &&
                value.email_sender &&
                value.address_reciever &&
                value.building_reciever &&
                value.moreaddress_reciever &&
                value.name_reciever &&
                value.tel_reciever &&
                value.email_reciever  ) {
                this.buttonNextStep2 = true;
            }
            else{
                this.buttonNextStep2 = false;
            }
        })
    }

    onChangeStep3() {
        this.reserve.valueChanges.subscribe(value=>{
            if (value.car_type) {
                this.buttonNextStep3 = true;
            }
            else{
                this.buttonNextStep3 = false;
            }
        })
    }

    onChangeStep4() {
        this.reserve.valueChanges.subscribe(value=>{
            if (value.date && value.time) {
                this.buttonNextStep1 = false;
            }
        })
    }

    // end of button next step

    // start modal

    // open() {
    //     const modalRef = this.modalService.open(NgbdModalContent);
    //     modalRef.componentInstance.name = 'World';
    //   }

    // end of modal

    // start alert modal


    // public changeSuccessMessage() { this._success.next(`${new Date()} - Message successfully changed.`); }

    //   end of modal


}
