<!-- Page banner Area -->
<div class="page-banner bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Terms Condition</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Terms Condition</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Terms ConditionArea -->
<div class="privacy-policy ptb-100">
    <div class="container">
        <div class="privacy-policy-text ">
            <h2 style="color: #5799d0;">เงื่อนไขการให้บริการ</h2>
            <ol>
                <li>
                ให้บริการรับ-ส่งสินค้าโดยมีขนาดความยาวรวมของสิ้นค้าต่อชิ้น (ก+ย+ส) ไม่เกิน 400 ซม. โดยความยาวสูงสุดของด้านใดด้านหนึ่งไม่เกิน 200 ซม. และมีน้ำหนักสูงสุดต่อชิ้นไม่เกิน 50 กก.
                </li>
                <li>
                กรณีที่สินค้ามีความยาวรวมตั้งแต่ 235 - 400 ซม. หรือมีน้ำหนักตั้งแต่ 36 - 50 กก. ผู้ใช้บริการต้องเป็นผู้จัดเตรียมผู้ช่วยในการยกสินค้าขึ้น-ลงรถ หรือทำการจ้างพนักงานช่วยยกจากทางบริษัท
                </li>
                <li>
                ผู้ใช้บริการจะต้องตรวจสอบสิ่งของที่จะส่งว่าได้รับการบรรจุหีบห่ออย่างแน่นหนาเพียงพอเพื่อกันไม่ให้เกิดความเสียหายในระหว่างการเคลื่อนย้ายและการขนส่งก่อนการส่งมอบสินค้าให้กับพนักงานรับสินค้า
                </li>
                <li>
                บริษัทจะไม่รับผิดชอบในการให้บริการปกป้องสินค้าโภคภัณฑ์ที่มีลักษณะเน่าเสียง่ายหรือสินค้าโภคภัณฑ์ที่ต้องการได้รับการปกป้องจากความร้อนหรือความเย็น
                </li>
                <li>
                สินค้าที่เปราะบางหรือแตกหักได้ง่ายจะต้องมีการติดป้ายชี้บ่งสินค้าจากทางผู้ใช้บริการและแจ้งรายละเอียดในการใช้บริการก่อนทำการส่งมอบสินค้า
                </li>
                <li>
                ในกรณีไม่พบผู้รับตามที่อยู่ที่ระบุไว้โดยผู้ใช้บริการ หรือไม่มีผู้รับของ และผู้ใช้บริการไม่มีคำชี้แจงอื่น ๆ  บริษัทจะทำการติดต่อผู้ใช้บริการเพื่อส่งสินค้ากลับคืน (คิดค่าบริการส่งสินค้ากลับ) และในกรณีที่ไม่สามารถติดต่อผู้ใช้บริการได้ ทางบริษัทจะทำการเก็บสินค้าไว้ให้ภายใน 3 วันโดยคิดค่าบริการฝากสินค้า 100 บาท/ชิ้น/วัน หากไม่สามารถติดต่อผู้ใช้บริการได้เกินกว่า 3 วัน ทางบริษัทขอสงวนสิทธิ์ในการทำลายสินค้าโดยไม่ต้องแจ้งให้ผู้ใช้บริการทราบล่วงหน้า
                </li>
                <li>
                ในการรับส่งสินค้าพนักงานขับรถจะไม่มีการเปิดและตรวจสอบสินค้าที่จะจัดส่ง ดังนั้นพนักงานขับรถและบริษัทจะไม่รับผิดชอบหรือไม่มีความรับผิดทางกฎหมายอันเกิดมาจากการขนส่งสินค้าหรือสิ่งของนั้น ๆ
                </li>
            </ol>
        </div>

        <div class="privacy-policy-text ">
            <h2 style="color: #5799d0;">การรับประกันความเสียหายของสินค้า</h2>
            <ol>
                <li>
                    บริษัทให้ความคุ้มครองสินค้าที่จัดส่งในกรณีสูญหายหรือเสียหายสูงสุดไม่เกิน 2,000 บาทต่อชิ้น โดยพิจารณาจากความสมบูรณ์ของสภาพของบรรจุภัณฑ์ภายนอกและหลักฐานอื่นๆเช่น รูปถ่ายสินค้าก่อนและหลังการจัดส่ง
                </li>
                <li>
                    สินค้าในกลุ่มอาหาร ผักผลไม้ เครื่องดื่ม และสินค้าที่เปราะบางหรือแตกหักง่าย บริษัทจะให้ความคุ้มครองเฉพาะกรณีที่สินค้าสูญหายโดยรับผิดชอบสูงสุดไม่เกิน 2,000 บาทต่อชิ้น
                </li>
                <li>
                    ผู้ใช้บริการต้องทำการติดต่อเพื่อแจ้งข้อมูลสินค้า รูปถ่ายบรรจุภัณฑ์ที่สมบูรณ์และหลักฐานความเสียหายที่เกิดขึ้นภายใน 24 ชั่วโมงหลังจากรับสินค้าเพื่อให้บริษัททำการตรวจสอบ หากมีการแจ้งนอกเหนือจากเวลาที่กำหนดจะไม่อยู่ในความรับผิดชอบของทางบริษัท
                </li>
            </ol>
        </div>
        <div class="privacy-policy-text ">
            <h2 style="color: #5799d0;">สินค้าที่ไม่อยู่ในเงื่อนไขการให้บริการ</h2>
            <ol>
                <li>
                    สิ่งที่ผิดกฎหมาย เช่น สารเสพติดหรือยาเสพติด,สินค้าละเมิดลิขสิทธิ์,ซากสัตว์หรือชิ้นส่วนสัตว์ผิดกฎหมายและสิ่งอื่นใดที่ผิดกฎหมาย ผิดกฎระเบียบ หรือผิดต่อบทบัญญัติใดๆที่ถูกระบุไว้ในกฎหมาย
                </li>
                <li>
                    อาวุธและสิ่งของมีคมหรือมีอันตรายทุกประเภทเช่น ดาบ,ปืน,ระเบิด,หรืออุปกรณ์จุดระเบิดทุกประเภทเช่น ดินระเบิด เป็นต้น
                </li>
                <li>
                    วัตถุไวไฟทุกชนิด เช่น แอลกอฮอลล์ ถังบรรจุก๊าซทุกชนิด
                </li>
                <li>
                    สินค้ามีค่าหรือสิ่งของที่มีมูลค่าสูงเช่นเงินสด,ทองคำ,เครื่องประดับและจิวเวอร์รี่
                </li>
                <li>
                    สิ่งมีชีวิตทุกชนิด 
                </li>
                <li>
                    ชิ้นส่วนมนุษย์ หรือเถ้ากระดูกมนุษย์ 
                </li>
                <li>
                    สิ่งของที่เสื่อมค่าง่ายหรือสิ่งที่เน่าเสียง่ายกว่าปกติ เช่น เนื้อสัตว์สด อาหารสด ผลไม้สุก ขนมเค้ก
                </li>
            </ol>
        </div>
    </div>
</div>
<!-- End Terms Condition Area -->

<!-- Newsletter Area -->
<!-- <div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required="" autocomplete="off">
                        <button type="submit" class="btn btn-primary">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Newsletter Area -->
