import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { GoogleTagManagerService } from "angular-google-tag-manager";

@Injectable({
  providedIn: 'root'
})
export class CookieServiceService {
    isAcceptCookies = false;
    isShowBanner = true
    hasAcceptBefore = localStorage.getItem("cookieconsent")
  constructor(
    private router: Router,
    private gtmService: GoogleTagManagerService

  ) { }

  acceptCookie() {
    localStorage.setItem("cookieconsent", "true");
    this.hasAcceptBefore = localStorage.getItem("cookieconsent")
    this.isAcceptCookies = true;
    this.isShowBanner = false;
    const gtmTag = {
        event: "page",
        isGranted: 'true',
        pageName: this.router.url,
    };

    this.gtmService.addGtmToDom().then((_) => {
        this.gtmService.pushTag(gtmTag);
    });
}

declineCookie(){
    this.isAcceptCookies = false;
    this.isShowBanner = false;
}
}
