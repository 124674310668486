
<div style="margin-top: 8%;">
    <div class="container mt-4">
        <div class="digital-content">
            <!-- <h3> <span>นโยบายความเป็นส่วนตัว</span></h3>
            <div class="bar"></div> -->
            <h4 style="color: #5799d0;">นโยบายความเป็นส่วนตัว</h4>
            <p>นโยบายนี้ครอบคลุมการรักษาข้อมูลส่วนบุคคล ที่เรารวบรวมเมื่อท่านมีการเข้าระบบ นโยบายนี้ไม่สามารถใช้ได้กับบริษัทที่เราไม่ได้เป็นเจ้าของหรือบุคคลที่เราไม่ได้ว่าจ้างหรือแม้ว่าท่านจะมีการเข้าถึงเว็บไซต์หรือบริการของบริษัทเหล่านั้นผ่านทางเว็บไซต์ของเราก็ตาม เราจะไม่เปิดเผยข้อมูลหรือส่งมอบข้อมูลของท่านต่อองค์กรอื่น</p>
        </div>
        <div class="mt-4 mb-4">
            <h4 style="color: #5799d0;">การใช้และการเปิดเผยข้อมูลส่วนตัว</h4>
            <p>เราจะใช้ข้อมูลส่วนที่เราได้รับจากท่านเพื่อดำเนินการหรือสนับสนุนการใช้บริการ เว็บไซต์ thepublisherth.com อาจมีการเชื่อมโยงไปยังเว็บไซต์อื่นๆ ที่ไม่อยู่ภายใต้การควบคุมของเรา เราไม่สามารถรับผิดชอบต่อ การป้องกันและความเป็นส่วนตัวของข้อมูลใดๆ ที่ท่านให้ในขณะที่การเยี่ยมชมเว็บไซต์ดังกล่าว และยอมรับความรับผิดชอบหรือไม่รับผิดสำหรับเนื้อหาหรือการใช้งานของเว็บไซต์ของบุคคลที่สามเหล่านี้ เราขอแนะนำให้ท่านอ่านนโยบายความเป็นส่วนตัวของเว็บไซต์ของบุคคลที่สามในแต่ละเว็บไซต์ที่ท่านเยี่ยมชม</p>
        </div>
</div>
